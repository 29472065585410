<template>
  <b-card title="Basında Biz">
    <b-carousel
      v-if="press"
      id="campaign-carousel"
      controls
      indicators
    >
      <b-carousel-slide
        v-for="(item,key) in press"
        :key="key"
      >
        <template v-slot:img>
          <b-card
            :img-src="'https://inallar.com.tr/media/press_releases/' + item.image"
            img-top
            no-body
          >
            <b-card-body class="text-center">
              <b-card-title>
                <div>
                  {{ item.title }}
                </div>
                <small>{{ moment(item.ndate).format('DD.MM.YYYY') }}</small>
              </b-card-title>
            </b-card-body>
          </b-card>
        </template>
      </b-carousel-slide>
    </b-carousel>
  </b-card>
</template>

<script>
import {
  BCard, BCarousel, BCarouselSlide, BCardBody, BCardTitle,
} from 'bootstrap-vue'

export default {
  name: 'PressReleases',
  components: {
    BCard,
    BCarousel,
    BCarouselSlide,
    BCardBody,
    BCardTitle,
  },
  computed: {
    press() {
      return this.$store.getters['company/getPressReleases']
    },
  },
  created() {
    this.getPressReleases()
  },
  methods: {
    getPressReleases() {
      this.$store.dispatch('company/press_releases')
    },
  },
}
</script>

<style>
body #campaign-carousel .carousel-indicators li {
  background-color: #000
}
.dark-layout #campaign-carousel .carousel-indicators li {
  background-color: #fff
}
</style>
