<template>
  <div>
    <b-row>
      <b-col
        xs="12"
        md="4"
      >
        <history />
      </b-col>
      <b-col
        xs="12"
        md="8"
      >
        <b-row>
          <b-col cols="12">
            <awards />
          </b-col>
          <b-col cols="6">
            <quality-policy />
          </b-col>
          <b-col cols="6">
            <press-releases />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import History from '@/views/Portal/Company/History.vue'
import PressReleases from '@/views/Portal/Company/Press_releases.vue'
import Awards from '@/views/Portal/Company/Awards.vue'
import QualityPolicy from '@/views/Portal/Company/Quality_policy.vue'

export default {
  name: 'Company',
  components: {
    BRow,
    BCol,
    History,
    Awards,
    QualityPolicy,
    PressReleases,
  },
}
</script>

<style scoped>

</style>
