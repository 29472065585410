<template>
  <b-card title="Kalite Politikamız">
    <div v-html="qualityPolicy.content" />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  name: 'QualityPolicy',
  components: {
    BCard,
  },
  computed: {
    qualityPolicy() {
      return this.$store.getters['company/getQualityPolicy']
    },
  },
  created() {
    this.getQualityPolicy()
  },
  methods: {
    getQualityPolicy() {
      this.$store.dispatch('company/qualityPolicy')
    },
  },
}
</script>

<style scoped>

</style>
