<template>
  <b-card title="Tarihçe">
    <app-timeline>
      <app-timeline-item
        v-for="item in history"
        :key="item.id"
        variant="primary"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6 v-html="item.title" />
          <div class="text-danger font-weight-bold">
            {{ item.year }}
          </div>
        </div>
        <div v-html="item.content" />
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  name: 'History',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
  },
  computed: {
    history() {
      return this.$store.getters['company/getHistory']
    },
  },
  created() {
    this.getHistory()
  },
  methods: {
    getHistory() {
      this.$store.dispatch('company/history')
    },
  },
}
</script>

<style scoped>

</style>
