<template>
  <div v-if="awards.length > 0">
    <b-card>
      <b-card-title>Ödüllerimiz</b-card-title>
      <b-carousel
        v-if="awards"
        id="campaign-carousel"
        controls
        indicators
      >
        <b-carousel-slide
          v-for="(award,key) in awards"
          :key="key"
        >
          <template v-slot:img>
            <b-row>
              <b-col
                v-for="item in award"
                :key="item.id"
                cols="12"
                md="3"
              >
                <b-card
                  :img-src="'https://inallar.com.tr/media/awards/' + item.image"
                  img-top
                  no-body
                >
                  <b-card-body class="text-center">
                    <b-card-title>
                      <div class="font-small-3">
                        {{ item.title }}
                      </div>
                      <small>{{ item.year }}</small>
                    </b-card-title>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </template>
        </b-carousel-slide>
      </b-carousel>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardBody,
  BCarousel,
  BCarouselSlide,
  BRow,
  BCol,
} from 'bootstrap-vue'

export default {
  name: 'Awards',
  components: {
    BCarousel,
    BCarouselSlide,
    BCard,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
  },
  computed: {
    awards() {
      return this.$store.getters['company/getAwards']
    },
  },
  created() {
    this.getAwards()
  },
  methods: {
    getAwards() {
      this.$store.dispatch('company/awards')
    },
  },
}
</script>

<style>
body #campaign-carousel .carousel-indicators li {
  background-color: #000
}
.dark-layout #campaign-carousel .carousel-indicators li {
  background-color: #fff
}
</style>
